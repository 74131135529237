import { Link } from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
//import "../styles/tdemo.module.css"
import * as headerStyles from '../styles/tdemo.module.css';

const DemoPage = () => (
  <Layout>
    <SEO
      title="Page Three"
      description="Page three description"
    />

    <header className={headerStyles.he}>
      <h1 className={headerStyles.title}>Today's Demo Page</h1>

      <nav>
        <ul className={headerStyles.navList} >
          <li>
            <Link className={headerStyles.navItem}>Home</Link>
          </li>

          <li>
            <Link className={headerStyles.navItem}>Blog</Link>
          </li>

          <li>
            <Link className={headerStyles.navItem}>About</Link>
          </li>

          <li>
            <Link className={headerStyles.navItem}>Contact</Link>
          </li>
        </ul>
      </nav>
    </header>

    <Link to="/">Go back to the homepage</Link>
  </Layout>

);

export default DemoPage;
